<template>
  <div class="cursor-pointer" :class="scrollPosition > 100 ? 'active' : 'inactive'" @click="scrollToTop">
    <img :src="scrollerIcon" class="w-16">
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import scrollerIcon from "@/assets/icons/scroller.png";

export default defineComponent({
  name: "Scroller",
  components: {},
  setup() {
    const scrollPosition = ref(0);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };

    window.addEventListener("scroll", () => {
      scrollPosition.value = window.scrollY;
    });

    return {
      scrollerIcon,
      scrollPosition,
      scrollToTop
    };
  },
});
</script>

<style lang="scss">
.inactive {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.active {
  opacity: 0;
}

@media screen and (min-width: 1024px) {
  .active {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
}
</style>