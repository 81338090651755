<template>
  <GoogleMap
    class="w-full h-full"
    :api-key="googleMapKey"
    :center="center"
    :zoom="9"
    :fullscreen-control="false"
    :street-view-control="false"
    :zoom-control="false"
    :map-type-control="false"
    map-id="a49dda3b34d99f52"
  >
    <Marker
      :options="{
        position: markerPoints,
        icon: {
          url: markerIcon,
          scaledSize: {
            width: 35,
            height: 35
          }
        }
      }"
    />
  </GoogleMap>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
import markerIcon from "@/assets/icons/marker.svg";

export default defineComponent({
  name: "Map",
  components: {
    GoogleMap,
    Marker
  },
  setup() {
    const googleMapKey = import.meta.env.VITE_GOOGLE_MAP_KEY as string;
    const markerPoints: Object = { lat: 43.60798040261826, lng: 2.2391675551618686 };
    const center: Object = markerPoints;

    return {
      googleMapKey,
      center,
      markerPoints,
      markerIcon
    };
  }
});
</script>

<style lang="css" scoped>
</style>