<template>
  <div></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Booking",
  components: {},
  setup() {
    return {};
  },
});
</script>

<style lang="scss"></style>
