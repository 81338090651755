<template>
  <div class="flex flex-col-reverse md:grid grid-cols-3 gap-10 text-xl leading-relaxed">
    <div class="pt-32 md:pt-0">
      <SocialMedia/>
    </div>
    <div class="md:pl-8 xl:pl-20 col-span-2">
      <h3 class="text-3xl font-bold mb-6">Besoin d'informations supplémentaires ?</h3>
      <p class="text-xl leading-relaxed font-light">
        Contactez notre équipe pour toute question ou demande de réservation.
        Nous sommes là pour rendre votre séjour inoubliable en toute <strong>discrétion</strong>.
      </p>
      <div v-if="!formSubmitted" class="mt-16">
        <div class="grid lg:grid-cols-2 lg:gap-10 text-xl">
          <div class="mb-6">
            <label for="firstname" class="block mb-2 font-base">
              Prénom
            </label>
            <input
              name="firstname"
              type="text"
              class="bg-gray-200 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-lg block w-full p-2.5"
              required
              @input="handleInput"
            />
          </div>
          <div class="mb-6">
            <label for="lastname" class="block mb-2 font-base">
              Nom
            </label>
            <input
              name="lastname"
              type="text"
              class="bg-gray-200 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-lg block w-full p-2.5"
              required
              @input="handleInput"
            />
          </div>
        </div>
        <div class="mb-12">
          <label for="email" class="block mb-2 font-base">
            Email *
          </label>
          <input
            name="email"
            type="text"
            class="bg-gray-200 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-lg block w-full p-2.5"
            required
            @input="handleInput"
          />
          <p v-if="getFormError('email')" class="mt-3 text-red-400 text-base">{{ getFormError('email') }}</p>
        </div>
        <div>
          <label for="request" class="block mb-2 font-base">
            Votre demande *
          </label>
          <textarea
            name="request"
            type="text"
            class="bg-gray-200 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-lg block w-full h-36 p-2.5"
            required
            @input="handleInput"
          ></textarea>
          <p v-if="getFormError('request')" class="mt-3 text-red-400 text-base">{{ getFormError('request') }}</p>
        </div>
        <div class="flex mt-8 cursor-pointer">
          <div class="flex justify-center items-center h-5 pr-3 checkbox" @click="acceptRgpd()">
            <input type="checkbox" class="w-5 h-5 sr-only" @input="acceptRgpd()"/>
            <div class="w-5 h-5 border relative rounded-md" :class="isRgpdAccepted() ? 'border-primary' : 'border-slate-300'">
              <span v-if="isRgpdAccepted()" class="absolute bg-primary w-3 h-3 rounded-sm icon"></span>
            </div>
          </div>
          <label class="pl-1 text-base">En soumettant le formulaire, j'accepte <a href="/donnees-personnelles" target="_blank" class="text-primary cursor-pointer">les conditions relatives aux traitements des données personnelles</a>.</label>
        </div>
        <div>
          <p v-if="getFormError('rgpd')" class="mt-3 text-red-400 text-base">{{ getFormError('rgpd') }}</p>
        </div>
        <button type="button" class="flex items-center text-white bg-primary hover:bg-primary rounded-full px-10 py-3 mt-10 shadow-lg font-semibold montserrat" @click="submit">
          Envoyer
          <img :src="sendIcon" alt="send" class="w-6 ml-3 pt-1"/>
        </button>
        <p v-if="globalError != ''" class="mt-8 text-red-400 text-base">{{ globalError }}</p>
      </div>
      <div v-if="formSubmitted" class="mt-20">
        <div class="flex items-center justify-center">
          <img :src="checkIcon" alt="check" class="w-16 mr-10"/>
          <p class="text-xl text-left leading-relaxed">
            Votre message a bien été envoyé. Nous vous répondrons dans les plus brefs délais. Si besoin, vous pouvez également nous contacter directement via Instagram.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useReCaptcha } from 'vue-recaptcha-v3';

import SocialMedia from "@/components/SocialMedia.vue";
import sendIcon from "@/assets/icons/send.svg";
import checkIcon from "@/assets/icons/check.svg";
import ContactService from "@api/contactService";

export default defineComponent({
  name: "Contact",
  components: {
    SocialMedia
  },
  setup() {
    const recaptchaInstance = useReCaptcha();
    const formSubmitted = ref(false);
    const formValues = ref([
      {
        slug: "firstname",
        value: ""
      },
      {
        slug: "lastname",
        value: ""
      },
      {
        slug: "email",
        value: "",
        error: ""
      },
      {
        slug: "request",
        value: ""
      },
      {
        slug: "rgpd",
        value: false,
        error: ""
      }
    ]);
    const globalError = ref("");

    const handleInput = (e: any) => {
      const inputName: string = e.target.name;
      changeFormValue(inputName, e.target.value);
    }

    const validateEmail = (email: string) => {
      // eslint-disable-next-line no-useless-escape
      const pattern = /^[a-z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/;
      return pattern.test(email);
    }

    const isRgpdAccepted = () => {
      return formValues.value.find((input: any) => input.slug === "rgpd")?.value || false;
    };

    const acceptRgpd = () => {
      changeFormValue("rgpd", !isRgpdAccepted());
    };

    const getFormValue = (slug: string) => {
      return formValues.value.find((input: any) => input.slug === slug)?.value || "";
    };

    const getFormError = (slug: string) => {
      return formValues.value.find((input: any) => input.slug === slug)?.error || "";
    };

    const changeFormValue = (slug: string, value: any) => {
      formValues.value = formValues.value.map((input: any) => {
        if (input.slug === slug) input.value = value;
        return input;
      });
    };

    const changeFormError = (slug: string, error: string) => {
      formValues.value = formValues.value.map((input: any) => {
        if (input.slug === slug) input.error = error;
        return input;
      });
    };

    const resetErrors = () => {
      globalError.value = "";
      formValues.value = formValues.value.map((input: any) => {
        input.error = "";
        return input;
      });
    };

    const submit = async () => {
      await recaptchaInstance?.recaptchaLoaded();
      const token = await recaptchaInstance?.executeRecaptcha('login');

      let submitForm : boolean = true;
      resetErrors();

      // Check email is valid
      const email: string = getFormValue("email") as string;
      if (email == "" || !validateEmail(email)) {
        submitForm = false;
        changeFormError("email", "Veuillez renseigner une adresse mail valide.");
      }

      // Check request is not empty
      const request: string = getFormValue("request") as string;
      if (request == "" || request.length < 10) {
        submitForm = false;
        changeFormError("request", "Veuillez renseigner une demande valide.");
      }

      // Check if RGPD is accepted
      if (!isRgpdAccepted()) {
        submitForm = false;
        changeFormError("rgpd", "Veuillez accepter les conditions relatives aux traitements des données personnelles.");
      }

      if (submitForm) {
        const params: any[] = []
        formValues.value.forEach((input: any) => params[input.slug] = input.value);

        ContactService.sendContactForm(params, token)
          .then((data: any) => {
            if (data.success) formSubmitted.value = true;
            globalError.value = "Une erreur est survenue. Veuillez réessayer.";
          })
          .catch((error: any) => {
            console.log(error);
            globalError.value = "Une erreur est survenue. Veuillez réessayer.";
          });
      }
    }

    return {
      sendIcon,
      checkIcon,
      formSubmitted,
      formValues,
      globalError,
      getFormError,
      handleInput,
      isRgpdAccepted,
      acceptRgpd,
      submit
    };
  }
});
</script>

<style scoped lang="scss">
.checkbox {
  .icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
