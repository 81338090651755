<template>
  <div class="flex flex-col md:flex-row justify-between md:items-center text-sm">
    <RouterLink to="/" class="cursor-pointer mb-3 md:mb-0 uppercase text-xs">
      <strong>Luxury Suite Castres</strong>
    </RouterLink>
    <div class="md:ml-10 flex flex-col md:flex-row">
      <RouterLink to="/donnees-personnelles" class="md:ml-5 mt-2 md:mt-0 col-span-3 md:col-span-1 cursor-pointer">Conditions de collecte des données personnelles</RouterLink>
      <RouterLink to="/mentions-legales" class="md:ml-5 mt-2 md:mt-0 col-span-3 md:col-span-1 cursor-pointer">Mentions légales</RouterLink>
      <span class="md:ml-5 mt-2 md:mt-0 col-span-3 md:col-span-1">Tous droits réservés</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  components: {},
  setup() {
    return {};
  },
});
</script>

<style lang="scss"></style>
