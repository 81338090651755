<template>
  <div class="lg:grid grid-cols-2 gap-10 text-xl leading-relaxed font-light">
    <div class="lg:pr-8 xl:pr-32">
      <h2 class="text-4xl md:text-5xl font-semibold mb-16">
        Un espace luxueux et intimiste idéalement situé
      </h2>
      <p class="mb-10">
        Idéalement située dans l'hyper centre de Castres, notre Luxury Suite offre un refuge
        romantique au cœur d'une petite ville paisible. À seulement <strong>1 heure de
        Toulouse</strong> et à <strong>30 minutes d'Albi</strong>, notre logement vous permet de savourer
        <strong>le calme de Castres</strong> tout en bénéficiant des trésors de cette petite ville.
      </p>
      <p class="mb-10">
        <strong>Profitez d'une escapade intime</strong> dans un
        lieu stratégiquement positionné pour vous offrir le meilleur des deux
        mondes, entre nature et animations culturelles.
      </p>
      <a class="text-base text-center text-white bg-primary rounded-full px-5 py-3 font-semibold montserrat mx-auto block w-60 mt-16" href="https://www.airbnb.fr/rooms/1251380248056468024" target="_blank">Réserver maintenant</a>
    </div>
    <div class="h-96 mt-20 lg:h-auto lg:mt-0">
      <Map/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Map from "@/components/Map.vue";

export default defineComponent({
  name: "Location",
  components: {
    Map
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss">
.mapdiv {
  border-radius: .75rem;
}
</style>
