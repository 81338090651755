import './style.css'
import App from './App.vue'
import router from './router'
import { createApp } from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import i18n from './i18n'

router.beforeEach((to, _from, next) => {
  const lang = to.params.lang || 'fr';
  i18n.global.locale.value = lang;
  next();
});

const app = createApp(App)
  .use(router)
  .use(i18n);

VueReCaptcha.install(app, {
  siteKey: import.meta.env.VITE_RECAPTCHA_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true
  }
})

app.mount('#app')
