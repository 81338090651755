<template>
  <div class="home relative text-xl">
    <div>
      <Landing />
    </div>
    <div class="min-h-screen">
      <div class="pt-20">
        <Introduction />
      </div>
      <div class="pt-10 pb-20 lg:pb-10 2xl:pb-36 hidden lg:block">
        <InfiniteCarousel />
      </div>
      <div class="pt-10 pb-20 lg:pb-10 2xl:pb-36 block lg:hidden">
        <Gallery />
      </div>
      <div class="pb-24 md:pb-36">
        <Service />
      </div>
      <div
        id="emplacement"
        class="content pt-20 lg:pt-10 xl:pt-20 pb-24 md:pb-44"
      >
        <Location />
      </div>
      <div id="booking" class="content">
        <Booking />
      </div>
      <div id="contact" class="content pt-20 lg:pt-10 xl:pt-20 pb-20 md:pb-32">
        <Contact />
      </div>
      <div class="pt-10 pb-5 content">
        <Footer />
      </div>
    </div>
    <div class="scroller">
      <Scroller />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Header from "@/components/Header.vue";
import Landing from "@/components/Landing.vue";
import Service from "@/components/Service.vue";
import Location from "@/components/Location.vue";
import Booking from "@/components/Booking.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
import Scroller from "@/components/Scroller.vue";
import Introduction from "./Introduction.vue";
import InfiniteCarousel from "./InfiniteCarousel.vue";
import Gallery from "./Gallery.vue";

export default defineComponent({
  name: "Home",
  components: {
    Header,
    Landing,
    Introduction,
    InfiniteCarousel,
    Gallery,
    Service,
    Location,
    Booking,
    Contact,
    Footer,
    Scroller,
  },
});
</script>

<style lang="scss">
.scroller {
  position: fixed;
  bottom: 5vh;
  right: 2vw;
}
</style>
