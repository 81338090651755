<template>
  <div
    class="content 2xl:grid grid-cols-2 gap-10 text-xl leading-relaxed font-light"
  >
    <div class="2xl:pr-20">
      <div class="bg-service rounded-lg p-10 sm:p-16">
        <div
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 2xl:grid-cols-2 gap-16 text-xl tenor-sans-regular h-full"
        >
          <div class="flex items-center sm:text-center sm:block">
            <div
              class="shrink-0 bg-white rounded-lg w-16 h-16 sm:mx-auto sm:mb-6 p-3"
            >
              <img :src="bedIcon" alt="bed" class="w-20 mx-auto mb-4" />
            </div>
            <span class="ml-8 sm:pl-0">Lit Queen Size</span>
          </div>
          <div class="flex items-center sm:text-center sm:block">
            <div
              class="shrink-0 bg-white rounded-lg w-16 h-16 sm:mx-auto sm:mb-6 p-3"
            >
              <img :src="showerIcon" alt="shower" class="w-20 mx-auto mb-4" />
            </div>
            <span class="ml-8 sm:pl-0">Double douche luxieuse</span>
          </div>
          <div class="flex items-center sm:text-center sm:block">
            <div
              class="shrink-0 bg-white rounded-lg w-16 h-16 sm:mx-auto sm:mb-6 p-3"
            >
              <img :src="jaccuziIcon" alt="balneo" class="w-20 mx-auto mb-4" />
            </div>
            <span class="ml-8 sm:pl-0">Balneo</span>
          </div>
          <div class="flex items-center sm:text-center sm:block">
            <div
              class="shrink-0 bg-white rounded-lg w-16 h-16 sm:mx-auto sm:mb-6 p-3"
            >
              <img :src="massageIcon" alt="massage" class="w-20 mx-auto mb-4" />
            </div>
            <span class="ml-8 sm:pl-0">Table de massage</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-20 2xl:mt-10">
      <h3 class="text-2xl font-bold mb-16">
        Découvrez également dans ce logement
      </h3>
      <div class="md:grid grid-cols-5 xl:grid-cols-3 gap-10 text-xl">
        <div class="col-span-3 xl:col-span-2">
          <div class="flex items-center mb-7">
            <img :src="doorIcon" alt="free-arrival" class="w-6 mr-6" />
            <span>Arrivée autonome</span>
          </div>
          <div class="flex items-center mb-7">
            <img :src="bedSheetsIcon" alt="bed sheets" class="w-6 mr-6" />
            <span>Draps inclus</span>
          </div>
          <div class="flex items-center mb-7">
            <img :src="soapIcon" alt="linen" class="w-6 mr-6" />
            <span>Serviettes et savon fournis</span>
          </div>
          <div class="flex items-center mb-7">
            <img :src="tvIcon" alt="tv" class="w-6 mr-6" />
            <span>TV avec Amazon Prime Video, Netflix</span>
          </div>
          <div class="flex items-center mb-7">
            <img :src="audioIcon" alt="audio" class="w-6 mr-6" />
            <span>Système audio Bluetooth</span>
          </div>
        </div>
        <div class="mt-20 md:mt-0 col-span-2 xl:col-span-1">
          <div class="flex items-center mb-7">
            <img :src="wifiIcon" alt="wifi" class="w-6 mr-6" />
            <span>Wifi</span>
          </div>
          <div class="flex items-center mb-7">
            <img :src="kitchenIcon" alt="kitchen" class="w-6 mr-6" />
            <span>Cuisine</span>
          </div>
          <div class="flex items-center mb-7">
            <img :src="coffeeMakerIcon" alt="coffee maker" class="w-6 mr-6" />
            <span>Cafetière</span>
          </div>
          <div class="flex items-center mb-7">
            <img :src="hairDryerIcon" alt="hair dryer" class="w-6 mr-6" />
            <span>Sèche-cheveux</span>
          </div>
          <div class="flex items-center">
            <img :src="heatingIcon" alt="heating" class="w-6 mr-6" />
            <span>Chauffage</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import bedIcon from "@/assets/icons/bed.svg";
import showerIcon from "@/assets/icons/shower.svg";
import jaccuziIcon from "@/assets/icons/jaccuzi.svg";
import massageIcon from "@/assets/icons/massage.png";
import tvIcon from "@/assets/icons/tv.svg";
import audioIcon from "@/assets/icons/audio.svg";
import bedSheetsIcon from "@/assets/icons/bed-sheets.svg";
import soapIcon from "@/assets/icons/soap.svg";
import hairDryerIcon from "@/assets/icons/hair-dryer.svg";
import kitchenIcon from "@/assets/icons/kitchen.svg";
import coffeeMakerIcon from "@/assets/icons/coffee-machine.svg";
import tableIcon from "@/assets/icons/table.svg";
import heatingIcon from "@/assets/icons/temperature.svg";
import wifiIcon from "@/assets/icons/wifi.svg";
import doorIcon from "@/assets/icons/door.svg";

export default defineComponent({
  name: "Service",
  components: {},
  setup() {
    return {
      bedIcon,
      showerIcon,
      jaccuziIcon,
      massageIcon,
      tvIcon,
      audioIcon,
      bedSheetsIcon,
      soapIcon,
      hairDryerIcon,
      kitchenIcon,
      coffeeMakerIcon,
      tableIcon,
      heatingIcon,
      wifiIcon,
      doorIcon,
    };
  },
});
</script>

<style lang="scss">
.bg-service {
  background-color: #f7f7f7;
}
</style>
