export default class ContactService {

  private static BASE_API: string | undefined = import.meta.env.VITE_API_URL;

  public static sendContactForm(params: any, recaptchaToken: string = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${this.BASE_API}/request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...params, recaptchaToken })
      })
      .then(response => resolve(response.json()))
      .catch(error => {
        console.error(error);
        reject(error);
      });
    });
  }
}