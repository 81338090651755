<template>
  <div class="content min-h-screen pt-16">
    <div class="w-32 mx-auto">
      <RouterLink to="/">
        <img :src="contactLogo" alt="logo" class="w-32 mx-auto mb-4" />
      </RouterLink>
    </div>
    <div class="py-16 text-xl font-light">
      <h1 class="text-5xl font-semibold mb-8">Mentions légales</h1>
      <p class="mb-4">
        Le présent site est la propriété exclusive de Doriant Louit.
      </p>
      <p class="mb-4">
        Ce site est hébergé par OVH SAS 2 rue Kellermann, 59100 Roubaix, France
      </p>
      <div class="pt-10">
        <h2 class="text-2xl font-bold mb-4">Propriété intellectuelle</h2>
        <p>
          Le site et chacun des éléments, y compris mais sans limitation les
          logos, icônes, photographies, qui le composent sont protégés au titre
          de la législation française de la propriété intellectuelle. Les
          contenus figurant sur le site sont la propriété de Dorian Louit ou
          d'autres entreprises. Toute utilisation, reproduction ou
          représentation, par quelque procédé que ce soit, et sur quelque
          support que ce soit, de tout ou partie du site et/ou des éléments qui
          le composent n'est pas autorisée sans le consentement expresse de son
          auteur.
        </p>
      </div>
      <div class="pt-10">
        <h2 class="text-2xl font-bold mb-4">Données personnelles</h2>
        <p>
          De façon générale, vous avez la possibilité de parcourir notre site
          sans avoir à divulguer votre identité ni à fournir des informations
          personnelles vous concernant. Cependant, il peut arriver que nous
          sollicitions des informations, notamment lors du traitement d'une
          réservation ou d'une demande de renseignements. Ces informations
          peuvent être complétées afin d'optimiser la qualité de notre service.
        </p>
      </div>
    </div>
    <div class="py-10">
      <Footer />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import contactLogo from "@/assets/contact.png";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "LegalInformation",
  components: {
    Footer,
  },
  setup() {
    return { contactLogo };
  },
});
</script>

<style lang="scss"></style>
