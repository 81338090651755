<template>
  <div class="flex w-full">
    <div class="flex-none w-1/5">
      <div class="h-full p-2 image-container" @mouseenter="onHover(0)" @mouseleave="onLeave">
        <img :src="images[0]" class="block rounded-lg h-full w-full object-cover object-center image-hover" />
      </div>
    </div>
    <div class="flex-none w-1/5">
      <div class="h-1/2 p-2 image-container" @mouseenter="onHover(1)" @mouseleave="onLeave">
        <img :src="images[1]" class="block rounded-lg h-full w-full object-cover object-center image-hover" />
      </div>
      <div class="h-1/2 p-2 image-container" @mouseenter="onHover(2)" @mouseleave="onLeave">
        <img :src="images[2]" class="block rounded-lg h-full w-full object-cover object-center image-hover" />
      </div>
    </div>
    <div class="flex-none w-1/5">
      <div class="h-2/3 p-2 image-container" @mouseenter="onHover(3)" @mouseleave="onLeave">
        <img :src="images[3]" class="block rounded-lg h-full w-full object-cover object-center image-hover" />
      </div>
      <div class="h-1/3 p-2 image-container" @mouseenter="onHover(4)" @mouseleave="onLeave">
        <img :src="images[4]" class="block rounded-lg h-full w-full object-cover object-center image-hover" />
      </div>
    </div>
    <div class="flex-none w-1/5">
      <div class="h-1/2 p-2 image-container" @mouseenter="onHover(5)" @mouseleave="onLeave">
        <img :src="images[5]" class="block rounded-lg h-full w-full object-cover object-center image-hover" />
      </div>
      <div class="h-1/2 p-2 image-container" @mouseenter="onHover(6)" @mouseleave="onLeave">
        <img :src="images[6]" class="block rounded-lg h-full w-full object-cover object-center image-hover" />
      </div>
    </div>
    <div class="flex-none w-1/5">
      <div class="h-1/3 p-2 image-container" @mouseenter="onHover(7)" @mouseleave="onLeave">
        <img :src="images[7]" class="block rounded-lg h-full w-full object-cover object-center image-hover" />
      </div>
      <div class="h-1/3 p-2 image-container" @mouseenter="onHover(8)" @mouseleave="onLeave">
        <img :src="images[8]" class="block rounded-lg h-full w-full object-cover object-center image-hover" />
      </div>
      <div class="h-1/3 p-2 image-container" @mouseenter="onHover(9)" @mouseleave="onLeave">
        <img :src="images[9]" class="block rounded-lg h-full w-full object-cover object-center image-hover" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CarouselSection',
  props: {
    images: {
      type: Array as () => string[],
      required: true,
      validator: (value: string[]) => value.length === 10
    }
  },
  emits: ['hover-start', 'hover-end'],
  setup(_, { emit }) {
    const onHover = (index: number) => {
      emit('hover-start', index);
    };

    const onLeave = () => {
      emit('hover-end');
    };

    return {
      onHover,
      onLeave
    };
  }
});
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  transition: transform 0.3s ease-in-out;

  &:hover {
    z-index: 10;

    .image-hover {
      transform: scale(1.05);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    }
  }
}

.image-hover {
  transition: all 0.3s ease-in-out;
  will-change: transform;
}
</style>