<template>
  <div class="h-[75vh] overflow-hidden relative">
    <div
      ref="carouselRef"
      class="flex absolute w-[200%] h-full py-6"
      :class="{ 'is-paused': isPaused }"
    >
      <CarouselSection
        :images="images"
        @hover-start="handleHoverStart"
        @hover-end="handleHoverEnd"
      />
      <CarouselSection
        :images="images"
        @hover-start="handleHoverStart"
        @hover-end="handleHoverEnd"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import CarouselSection from './CarouselSection.vue';

import web1 from "@/assets/illustrations/web/0D1A0791.1.light.jpg";
import web2 from "@/assets/illustrations/web/0D1A0798.1.light.jpg";
import web3 from "@/assets/illustrations/web/0D1A0923-1.light.jpg";
import web4 from "@/assets/illustrations/web/0D1A0943.1.light.jpg";
import web5 from "@/assets/illustrations/web/0D1A0882-1.light.jpg";
import web6 from "@/assets/illustrations/web/0D1A0948.1.light.jpg";
import web7 from "@/assets/illustrations/web/0D1A0957-1.light.jpg";
import web8 from "@/assets/illustrations/web/0D1A0971-1.light.jpg";
import web9 from "@/assets/illustrations/web/0D1A1066-1.light.jpg";
import web10 from "@/assets/illustrations/web/0D1A1086.1.light.jpg";

export default defineComponent({
  name: 'InfiniteCarousel',
  components: {
    CarouselSection
  },
  setup() {
    const carouselRef = ref<HTMLElement | null>(null);
    const isPaused = ref(false);
    const hoveredIndex = ref<number | null>(null);
    const animation = ref<Animation | null>(null);
    const images = [web1, web2, web3, web4, web5, web6, web7, web8, web9, web10];

    onMounted(() => {
      const carousel = carouselRef.value;
      if (!carousel) return;

      animation.value = carousel.animate(
        [
          { transform: 'translateX(0%)' },
          { transform: 'translateX(-50%)' }
        ],
        {
          duration: 50000,
          iterations: Infinity,
          easing: 'linear'
        }
      );

      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          animation.value?.pause();
        } else if (!isPaused.value) {
          animation.value?.play();
        }
      });
    });

    const handleHoverStart = (index: number) => {
      isPaused.value = true;
      hoveredIndex.value = index;
      animation.value?.pause();
    };

    const handleHoverEnd = () => {
      isPaused.value = false;
      hoveredIndex.value = null;
      animation.value?.play();
    };

    return {
      carouselRef,
      images,
      isPaused,
      hoveredIndex,
      handleHoverStart,
      handleHoverEnd
    };
  }
});
</script>

<style lang="scss" scoped>
.is-paused {
  animation-play-state: paused;
}
</style>