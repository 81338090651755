<template>
  <div class="flex justify-between items-center text-white w-full">
    <div class="flex items-center">
      <img :src="logo" alt="luxury suite castres logo" class="h-10"/>
    </div>
    <nav>
      <ul class="flex items-center space-x-14 ml-auto uppercase font-semibold">
        <li>
          <a href="#suite" class="hover:text-gold-300">Découvrir la suite</a>
        </li>
        <li>
          <a href="#emplacement" class="hover:text-gold-300">Emplacement</a>
        </li>
        <li>
          <a href="#booking" class="hover:text-gold-300">Réservation</a>
        </li>
        <li>
          <a href="#contact" class="hover:text-gold-300">Contact</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import logo from "@/assets/logo.png";

export default defineComponent({
  name: "Header",
  components: {},
  setup() {
    return { logo };
  },
});
</script>

<style lang="scss"></style>
