<template>
  <div class="content block sm:hidden">
    <img
      :src="images[0]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover mb-5"
    />
    <img
      :src="images[1]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover mb-5"
    />
    <img
      :src="images[2]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover mb-5"
    />
    <img
      :src="images[5]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover mb-5"
    />
    <img
      :src="images[7]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover mb-5"
    />
    <img
      :src="images[8]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover mb-5"
    />
  </div>
  <div class="content hidden sm:grid grid-cols-2 gap-5">
    <img
      :src="images[0]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover"
    />
    <img
      :src="images[1]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover"
    />
    <img
      :src="images[2]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover"
    />
    <img
      :src="images[3]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover"
    />
    <img
      :src="images[4]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover"
    />
    <img
      :src="images[5]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover"
    />
    <img
      :src="images[6]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover"
    />
    <img
      :src="images[7]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover"
    />
    <img
      :src="images[8]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover"
    />
    <img
      :src="images[9]"
      class="block rounded-lg h-full w-full object-cover object-center image-hover"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import web1 from "@/assets/illustrations/web/0D1A0791.1.light.jpg";
import web2 from "@/assets/illustrations/web/0D1A0798.1.light.jpg";
import web3 from "@/assets/illustrations/web/0D1A0923-1.light.jpg";
import web4 from "@/assets/illustrations/web/0D1A0943.1.light.jpg";
import web5 from "@/assets/illustrations/web/0D1A0882-1.light.jpg";
import web6 from "@/assets/illustrations/web/0D1A0948.1.light.jpg";
import web7 from "@/assets/illustrations/web/0D1A0957-1.light.jpg";
import web8 from "@/assets/illustrations/web/0D1A0971-1.light.jpg";
import web9 from "@/assets/illustrations/web/0D1A1066-1.light.jpg";
import web10 from "@/assets/illustrations/web/0D1A1086.1.light.jpg";

export default defineComponent({
  name: "Gallery",
  components: {},
  setup() {
    const images = [
      web1,
      web2,
      web3,
      web4,
      web5,
      web6,
      web7,
      web8,
      web9,
      web10,
    ];

    return {
      images,
    };
  },
});
</script>

<style lang="scss"></style>
