<template>
  <div class="content min-h-screen pt-16">
    <div class="w-32 mx-auto">
      <RouterLink to="/">
        <img :src="contactLogo" alt="logo" class="w-32 mx-auto mb-4" />
      </RouterLink>
    </div>
    <div class="py-16 text-xl font-light">
      <h1 class="text-5xl font-semibold mb-8">
        Conditions de collecte des données personnelles
      </h1>
      <p class="mb-4">
        En soumettant le formulaire de demande d'informations, vous consentez à
        la collecte et au traitement de vos données personnelles conformément
        aux termes énoncés ci-dessous. Nous respectons votre vie privée et nous
        nous engageons à protéger les informations que vous nous fournissez.
      </p>
      <div class="pt-10">
        <h2 class="text-2xl font-bold mb-4">Données Collectées</h2>
        <ul>
          <li>Adresse e-mail (obligatoire)</li>
          <li>Message (obligatoire)</li>
          <li>Prénom (facultatif)</li>
          <li>Nom (facultatif)</li>
        </ul>
      </div>
      <div class="pt-10">
        <h2 class="text-2xl font-bold mb-4">Finalité de la collecte</h2>
        <p>
          Vos données seront utilisées exclusivement pour répondre à votre
          demande d'informations sur nos services. Elles nous permettront de
          vous contacter par e-mail pour vous fournir les informations
          demandées.
        </p>
      </div>
      <div class="pt-10">
        <h2 class="text-2xl font-bold mb-4">Conservation des données</h2>
        <p>
          Vos données seront conservées uniquement le temps nécessaire pour
          répondre à votre demande. Elles seront supprimées de nos systèmes
          après avoir atteint cet objectif.
        </p>
      </div>
      <div class="pt-10">
        <h2 class="text-2xl font-bold mb-4">Transmission des données</h2>
        <p>
          Vos données seront traitées avec la plus grande confidentialité. Elles
          ne seront ni revendues, ni partagées, ni transmises à des tiers sans
          votre consentement explicite, sauf si la loi l'exige.
        </p>
      </div>
      <div class="pt-10">
        <h2 class="text-2xl font-bold mb-4">Consentement</h2>
        <p>
          En soumettant le formulaire de demande d'informations, vous consentez
          à la collecte et au traitement de vos données personnelles
          conformément à ces conditions.
        </p>
      </div>
      <div class="pt-10">
        <h2 class="text-2xl font-bold mb-4">Droits de l'utilisateur</h2>
        <p>
          Vous avez le droit de demander l'accès, la rectification, la
          suppression ou la portabilité de vos données personnelles. Pour
          exercer ces droits, veuillez nous contacter à
          dorian.louit[at]gmail.com.
        </p>
      </div>
      <div class="pt-10">
        <h2 class="text-2xl font-bold mb-4">Sécurité des données</h2>
        <p>
          Nous mettons en place des mesures de sécurité appropriées pour
          protéger vos données contre tout accès non autorisé ou toute
          divulgation.
        </p>
      </div>
      <div class="pt-10">
        <p>
          En acceptant ces conditions, vous reconnaissez avoir lu et compris
          notre politique de confidentialité et consentez à la collecte et au
          traitement de vos données personnelles aux fins indiquées ci-dessus.
        </p>
      </div>
    </div>
    <div class="py-10">
      <Footer />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import contactLogo from "@/assets/contact.png";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "Rgpd",
  components: {
    Footer,
  },
  setup() {
    return { contactLogo };
  },
});
</script>

<style lang="scss"></style>
