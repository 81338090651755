<template>
  <div class="px-10 text-center">
    <img :src="contactLogo" alt="logo" class="w-2/3 md:w-full mx-auto mb-4"/>
    <p class="text-xl md:text-lg lg:text-xl leading-relaxed font-light mt-12">
      Retrouvez-nous sur les réseaux sociaux.
    </p>
    <div class="mt-20 mb-10 cursor-pointer" @click="openInstagram()">
      <img :src="instagramIcon" alt="instagram" class="w-14 mb-2 mx-auto"/>
      <span class="text-base">@luxurysuitecastres</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import contactLogo from "@/assets/contact.png";
import instagramIcon from "@/assets/icons/instagram.svg";

export default defineComponent({
  name: "SocialMedia",
  components: {},
  setup() {

    const openInstagram = () => {
      window.open("https://www.instagram.com/luxurysuitecastres/", "_blank");
    }

    return {
      contactLogo,
      instagramIcon,
      openInstagram
    };
  }
});
</script>

<style scoped lang="scss"></style>
