import Home from '@components/Home.vue';
import NotFound from '@components/NotFound.vue';
import LegalInformation from '@components/LegalInformation.vue';
import Rgpd from '@components/Rgpd.vue';
import * as VueRouter from 'vue-router'

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory('/'),
  scrollBehavior() {
    return { top: 0 };
  },
  routes: [
    {
      path: '/:lang(ca|fr)?',
      name: 'Home',
      component: Home
    },
    {
      path: '/:lang(ca|fr)?/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '/:lang(ca|fr)?/mentions-legales',
      name: 'LegalInformation',
      component: LegalInformation
    },
    {
      path: '/:lang(ca|fr)?/donnees-personnelles',
      name: 'Rgpd',
      component: Rgpd
    }
  ]
});

export default router;